import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import { config } from "../Components/Config";
import { Spinner } from 'react-bootstrap';

const Home = () => {
  const apiUrl = config.ENDPOINT;
  const pingAuth = config.PINGAUTH;

  const [ userInfo, setUserInfo ] = useState({});
  const [ user, setUser ] = useState({});
  const [ doors, setDoors ] = useState([]);
  const [ loading, setLoading ] = useState(true);
 
  useEffect(() => {
    const userLogin = localStorage.getItem('userInfo');
    if (userLogin) {
      setUserInfo(JSON.parse(userLogin));
    } else {
      window.location.replace('/');
    }
  }, []);

  useEffect(() => {
    if (userInfo.organizationId) {
      getUser();
    }
  }, [userInfo]);

  useEffect(() => {
    if (user.user_id) {
      getOwnedDoors();
    }
  }, [user]);

  const getUser = async () => {
    const fetchUser = await fetch(apiUrl + 'get/user', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        userId: userInfo.userId
      })
    });

    const dataUser = await fetchUser.json();
    if (dataUser.status === 200) {
      setUser(dataUser.values);
    }
  }

  const getOwnedDoors = async () => {
    const fetchDoors = await fetch(apiUrl + 'list/doors/user', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        userId: userInfo.userId
      })
    });
    const dataDoors = await fetchDoors.json();
    if (dataDoors.status === 200) {
      let listDoors = [];
      for (let d in dataDoors.values) {
        let door = dataDoors.values[d];
        door.sameNetwork = false;
        if ((user.button_status === 'Active') && (door.ip_address)) {
          door.sameNetwork = await sendPing(door);
        }
        listDoors.push(door);
      }
      setLoading(false);
      setDoors(listDoors);
    }
  }

  const openDoor = async (door) => {
    const fetchOpen = await fetch(apiUrl + 'open', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        doorId: door.door_id,
        accessType: "App Button",
        accessKey: user.button_key
      })
    });
    const dataOpen = await fetchOpen.json();
    if (dataOpen.status === 200) {
      const trigger = await sendTrigger(door);
      if (trigger) {
        toggleDoor(door.door_id);
      } else {
        console.log('Cannot connect to door');
      }
    }
  }

  const toggleDoor = async (doorId) => {
    const doorIndex = doors.findIndex(door => door.door_id === doorId);
    doors[doorIndex].status = "Opened";
    setDoors([...doors]);    
    setTimeout(() => {
      getOwnedDoors();
    }, 3000);
  }
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  }
  
  const randomKey = () => {
    const chars = '1234567890abcdefghijklmnopqrstuvwxyz';
    const digits = 16;
    let result = '';
    for (let i = 0; i < digits; i++) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  const sendPing = async (door) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const timeout = setTimeout(() => {
      controller.abort();
    }, 10000);
    try {
      const fetchPing = await fetch(apiUrl + 'ping/devices', {
        headers: {
          "Content-Type": "application/json",
          "Authorization": pingAuth
        },
        method: "POST",
        body: JSON.stringify({
          type: "ping",
          messageId: randomKey(),
          espId: door.nodemcu.toString(),
          doorId: door.door_id.toString(),
        }),
        signal
      });
      clearTimeout(timeout);
      const dataPing = await fetchPing.json();
      if (dataPing.values[0].status === 'true') {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      clearTimeout(timeout);
      if (error.name === 'AbortError') {
        console.error("Ping timed out:", door.ip_address);
      } else {
        console.error("Error pinging door:", door.ip_address, error);
      }
      return false;
    }
  }

  const sendTrigger = async (door) => {
    try {
      const fetchTrigger = await fetch(apiUrl + 'open/devices', {
        headers: {
          "Content-Type": "application/json",
          "Authorization": pingAuth
        },
        method: "POST",
        body: JSON.stringify({
          type: "open",
          messageId: randomKey(),
          espId: door.nodemcu.toString(),
          doorId: door.door_id.toString()
        })
      });
      const dataTrigger = await fetchTrigger.json();
      if (dataTrigger.values === 'true') {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error pinging door:", door.ip_address, error);
      return false;
    }
  }

  return (
    <div id="user-home">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 p-0">
            <Sidebar userInfo={userInfo} active={'Dashboard'}/>
          </div>
          <div className="col-md-9 p-5 main">
            <div className="hero">
              <div className="row">
                <div className="col-md-6">
                  <h2 className="mb-4">{userInfo.organizationName}</h2>
                  <p>{userInfo.organizationDescription}</p>
                </div>
                <div className="col-md-6">
                  <img className="w-100" src="/hero.png" alt="hero"/>
                </div>
              </div>
            </div>
            {loading ? (
              <div className="d-flex justify-content-center align-items-center mt-3">
                <Spinner/>
              </div>
            ) : ''}
            {doors.map((door, d) =>
              <div className="card mt-3" key={"door-" + d}>
                <div className="row align-items-center">
                  <div className="col-md-8">
                    <div className="card-body p-4">
                      <h4 className="card-title"><i className="bi bi-door-closed me-2"/> {door.name}</h4>
                      <p className="card-text">{door.place_name}, {door.floor}</p>
                      <p className="card-text">
                        {(userInfo.role === "Admin" && (
                          <small className="text-muted">{door.last_opened_on ? "Last opened by " + door.last_opened_by + " on " + formatDate(door.last_opened_on) : ""}</small>
                        ))}
                      </p>
                    </div>
                  </div>
                  {user.button_status === 'Active' ? (
                  <div className="col-md-4 text-center">
                    {door.status === 'Locked' ? (
                      <button className={door.sameNetwork === true ? "btn btn-lg btn-success" : "btn btn-lg btn-secondary"} onClick={() => openDoor(door)}>
                        <i className="bi bi-lock me-2"/> 
                        Click to open
                      </button>
                    ): (
                      <button className="btn btn-lg btn-warning" disabled={true}>
                        <i className="bi bi-unlock me-2"/> 
                        Door is opened
                      </button>
                    )}
                  </div>
                  ) : ''}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
